export const getParentElement = (
  element: HTMLElement | null,
  id: string
): HTMLElement | null => {
  if (!element) {
    return null;
  }
  if (element.id !== id) {
    return getParentElement(element.parentElement, id);
  }
  return element;
};
